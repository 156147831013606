/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import URL from "constant/url";
// import { useNavigate } from "react-router-dom";

export default function Footer() {
  // const navigate = useNavigate();
  // const domain = window.location.hostname;
  // const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;

  return (
    <>
      {/* FOOTER SECTION */}
      <section className="footer-section">
        <div className="container">
          <div className="middle-portion">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-md-block d-none">
                        <div className="column_one">
                          <h6>Sản phẩm</h6>
                          <ul className="list-unstyled">
                            <li>
                              <a href="">VPS</a>
                            </li>
                            <li>
                              <a href="">Dedicated Server</a>
                            </li>
                            <li>
                              <a href="">Proxy</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-md-block d-none">
                        <div className="column_two">
                          <h6>Danh mục</h6>
                          <ul className="list-unstyled">
                            <li>
                              <a href="https://manage.vpschinhhang247.com/management">Quản lý</a>
                            </li>
                            <li>
                              <a href="./agreement">Điều khoản</a>
                            </li>
                            <li>
                              <a href="./sla">SLA</a>
                            </li>
                            <li>
                              <a href="./contact">Liên hệ</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-lg-block d-none">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7450.608032391399!2d105.75465019532571!3d20.980447140195814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313453585629140f%3A0x284e05a1a0c4ef2c!2sANLAND%20LAKEVIEW!5e0!3m2!1sen!2s!4v1698811471829!5m2!1sen!2s"
                          width={256}
                          height={250}
                          style={{ border: 0, borderRadius: 10 }}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-sm-block">
                        <div className="mailing_column">
                          <h6 className="mailing_h6">
                            Liên hệ
                          </h6>
                          <ul className="list-unstyled">
                            <li className="mailing_address">
                              <i className="fa-solid fa-location-dot location" />
                              <span>
                                Toà nhà Anland Lakeview, KĐT Dương Nội,
                                Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội
                              </span>
                            </li>
                            <li className="mailing_address">
                              <i className="fa-solid fa-phone" />
                              <a href="tel:+(84)345993883">+84 34 5993 883</a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/VPSChinhHang247">
                                <i className="fa-brands fa-facebook-f" aria-hidden="true" />
                              </a>
                              <a href="mailto:admin@vpschinhhang247.com">
                                <i className="fa-sharp fa-solid fa-envelope" aria-hidden="true" />
                              </a>
                              <a href="https://t.me/vpschinhhhang247">
                                <i className="fa-brands fa-telegram" aria-hidden="true" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-portion">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                <ul className="list-unstyled mb-0 bottom-portion_left_side">
                  <li>
                    <span>
                      Copyright 2023 © Since 2021 VPSCHINHHANG247. All Rights
                      Reserved.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-2 col-md-3 col-sm-12 col-xs-12 d-lg-block d-none" />
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-md-block d-none">
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
