/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import URL from "constant/url";
import { SITE_NAME } from "constant/app";
import { nanoid } from "@reduxjs/toolkit";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <section className="plans_section" id="awesome_plan">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="plans_content">
              <h2>Những Plan Tiêu Biểu</h2>
            </div>
          </div>
        </div>
        <div className="tabs-box tabs-options" style={{ marginBottom: "0px" }}>
          <div className="tab-content" data-aos="fade-up">
            <div id="windows" className="tab-pane fade in active show">
              <div className="row">
                {listPlanDetail.map((item) => (
                  <div
                    key={nanoid()}
                    className="col-lg-4 col-md-6 col-sm-6 col-xs-12 lg:mb-4"
                  >
                    <div
                      className="plans_box_content"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="plans_upper_portion">
                        <figure className="mb-0">
                          <img
                            src="./assets/images/plan_3.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="plans_lower_portion">
                        <div className="plans_lower_portion_text_wrapper">
                          <h4>{item.region}</h4>
                          <ul className="list-unstyled">
                            <li>
                              <i className="fa-solid fa-check" />
                              <span>CPU: </span>
                              {item.cpu}
                            </li>
                            <li>
                              <i className="fa-solid fa-check" />
                              <span>SSD: </span>
                              {item.ssd}
                            </li>
                            <li>
                              <i className="fa-solid fa-check" />
                              <span>IPv4: </span>
                              {item.ipv4}
                            </li>
                            <li>
                              <i className="fa-solid fa-check" />
                              <span>Bandwidth: </span>
                              {item.bandwidth}
                            </li>
                            <li>
                              <i className="fa-solid fa-check" />
                              <span>Ethernet port: </span>
                              {item.ethernet_port}
                            </li>
                          </ul>
                          <div className="price_span_wrapper">
                            <span className="price">{item.price}</span>
                            <span className="month"> VNĐ/tháng</span>
                          </div>
                          <div className="btn_wrapper">
                            <a
                              href={URL.BuyProduct}
                              className="text-decoration-none view_plans_btn"
                            >
                              Mua ngay
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="plans_list_wrapper" data-aos="fade-up">
          <ul className="list-unstyled mb-0">
            <li>
              <i className="fa-solid fa-check" />
              Hỗ trợ 24/7
            </li>
            <li>
              <i className="fa-solid fa-check" />
              Cài đặt nhanh chóng
            </li>
            <li>
              <i className="fa-solid fa-check" />
              100% Network Uptime
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
