/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <div className="dropdown-menu drop-down-content">
      <ul
        className="list-unstyled drop-down-pages"
        style={{ width: "450px", padding: "8px" }}
      >
        <div className="row">
          <li className="nav-item col-md-6">
            <span className="ml-2">KHU VỰC VIỆT NAM</span>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListServerVN);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/vietnam.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Việt Nam (VN)</span>
            </a>
          </li>
          <li className="nav-item col-md-6">
            <span className="ml-2">KHU VỰC CHÂU Á</span>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListServerSG);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/singapore.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Singapore (SG)</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListServerAU);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/australia.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Úc (AU)</span>
            </a>
          </li>
          <li className="nav-item col-md-6">
            <span className="ml-2">KHU VỰC CHÂU ÂU</span>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListServerUK);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/united-kingdom.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Anh (UK)</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListServerDE);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/germany.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Đức (DE)</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListServerFR);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/france.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Pháp (FR)</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListServerNL);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/netherlands.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Hà Lan (NL)</span>
            </a>
          </li>
          <li className="nav-item col-md-6">
            <span className="ml-2">KHU VỰC ÂU MỸ</span>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListServerUS);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/united-states.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Mỹ (US)</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListServerCA);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/canada.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Canada (CA)</span>
            </a>
          </li>
          <li className="nav-item col-md-6">
            <span className="ml-2">THEO MỤC ĐÍCH</span>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListServerEU);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/european-union.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS Châu Âu (PTU)</span>
            </a>
            <a
              className="dropdown-item nav-link"
              href=""
              onClick={() => {
                navigate(URL.ListVPSGPU);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/gpu.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>VPS GPU</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}
