import { Helmet, HelmetProvider } from "react-helmet-async";
import BannerBuyServer from "./BannerBuyServer";
import Footer from "./Footer";

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout(props: MainLayoutProps) {
  const { children } = props;

  return (
    <HelmetProvider>
      <div className="sections_wrapper">
        {children}
        <BannerBuyServer />
        <Footer />
      </div>
      <Helmet>
        <script src="./assets/js/popper.min.js"></script>
        <script src="./assets/js/bootstrap.min.js"></script>
        <script src="./assets/js/counter.js"></script>
        <script src="./assets/js/animation.js"></script>
        <script src="./assets/js/jquery_ui.js"></script>
      </Helmet>
    </HelmetProvider>
  );
}
