import URL from "constant/url";
import Header from "layouts/Header";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <>
      <div className="contact-sub-banner-section">
        <Header />
        <section className="banner-section contact-banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner-section-content" data-aos="fade-up">
                  <h1 data-aos="fade-up">Hướng dẫn sử dụng</h1>
                  <p className="pp" data-aos="fade-right">
                    Tổng hợp các hướng dẫn hỗ trợ kĩ thuật và các tips hữu ích.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              >
                <div className="banner_image">
                  <figure className="mb-0">
                    <img style={{marginTop: '-50px', width: '400px'}}
                      src="./assets/images/web_hosting_image.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="register_reasons_section">
        <div className="container">
          <div
            className="register_reasons_box hover_effect aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="register_reasons_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/register_reasons_2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="register_reasons_box_content">
                  <a
                    style={{
                      fontSize: "24px",
                      color: "#292b33",
                      fontWeight: 600,
                    }}
                    href={URL.Guide1}
                  >
                    Hướng dẫn tăng cường bảo mật VPS
                  </a>
                  <p className="pp mb-0 mt-2">
                    Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS.
                    Hãy đọc bài viết sau để biết thêm chi tiết!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="register_reasons_box hover_effect aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="register_reasons_box_content register_reasons_middle_box_content">
                  <a
                    style={{
                      fontSize: "24px",
                      color: "#292b33",
                      fontWeight: 600,
                    }}
                    href={URL.Guide2}
                  >
                    Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                  </a>
                  <p className="pp mb-0 mt-2">
                    Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng dòng
                    lệnh hoặc cài đặt trong User Accounts...
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="register_reasons_image register_reasons_middle_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/register_reasons_2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div
            className="register_reasons_box hover_effect aos-init"
            data-aos="fade-up"
          >
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="register_reasons_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/register_reasons_2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="register_reasons_box_content">
                  <a
                    style={{
                      fontSize: "24px",
                      color: "#292b33",
                      fontWeight: 600,
                    }}
                    href={URL.Guide3}
                  >
                    Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                  </a>
                  <p className="pp mb-0 mt-2">
                    Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ
                    Disk Management...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
