/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function DedicatedServerCategory() {
  const navigate = useNavigate();
  return (
    <div className="dropdown-menu drop-down-content">
      <ul
        className="list-unstyled drop-down-pages"
        style={{ width: "250px", padding: "8px" }}
      >
        <div className="row">
          <li className="nav-item col-md-6">
            <a
              className="dropdown-item nav-link pb-2"
              href=""
              onClick={() => {
                navigate(URL.ListDedicatedServerEU);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/european-union.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>Dediacated Châu Âu</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListDedicatedServerUS);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/united-states.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span>Dediacated Mỹ</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}
