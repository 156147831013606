/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

import Header from "./Header";
import URL from "constant/url";
import MapLocation from "./MapLocation";
import FeaturePlan from "components/FeaturePlan";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  return (
    <>
      <div className="sub-banner-section">
        <Header />
        {/* BANNER SECTION */}
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner-section-content" data-aos="fade-up">
                  <h1 data-aos="fade-up">
                    Dịch vụ máy chủ ảo chuyên nghiệp, đa dạng, tự động,
                    <span> giá hợp lí</span>
                  </h1>
                  <div className="btn_wrapper" data-aos="fade-up">
                    <a
                      href={URL.BuyProduct}
                      className="text-decoration-none view_plans_btn"
                    >
                      Mua ngay
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/web_hosting_banner_image.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="four_icons_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div
                className="four_icons_box hover_effect"
                style={{ marginBottom: "20px" }}
              >
                <figure>
                  <img
                    src="./assets/images/four_icons_1.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>VPS Services</p>
                <span>
                  Đa dạng quốc gia, nhiều dải IP mang đến nhiều sự lựa chọn.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="four_icons_box hover_effect">
                <figure>
                  <img
                    src="./assets/images/four_icons_2.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Web Security</p>
                <span>
                  Mọi thông tin khách hàng được mã hóa. Hạn chế rủi ro hệ thống.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="four_icons_box hover_effect">
                <figure>
                  <img
                    src="./assets/images/four_icons_3.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Easy Setup</p>
                <span>
                  Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturePlan />
      <section className="web_hosting_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="web_hosting_image">
                <figure className="mb-0">
                  <img src="./assets/images/web_hosting_image.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="web_hosting_content" data-aos="fade-right">
                <h2>Đa dạng tùy chọn, đa dạng Hệ điều hành</h2>
                <p className="pp">
                  Quý khách có thể tùy chọn cài đặt máy chủ tùy theo nhu cầu,
                  cài đặt các phần mềm cơ bản, cài sẵn hệ điều hành cần thiết.
                  Toàn bộ quá trình được vận hành tự động đảm bảo an toàn, bảo
                  mật thông tin khách hàng.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa-solid fa-check" />Ổ cứng SSD, phần cứng
                    mạnh mẽ.
                  </li>
                  <li>
                    <i className="fa-solid fa-check" />
                    Đường mạng tốc độ cao 1 Gbps.
                  </li>
                  <li>
                    <i className="fa-solid fa-check" />
                    IPv4 riêng cho mỗi VPS.
                  </li>
                  <li>
                    <i className="fa-solid fa-check" />
                    Toàn quyền sử dụng với full quyền administrator hoặc root.
                  </li>
                </ul>
                <div className="btn_wrapper">
                  <a
                    href={`https://manage.${window.location.hostname}/buying?type=VPS&nation=VN`}
                    className="text-decoration-none get_started_btn"
                  >
                    Trải nghiệm ngay
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="choose_hostiko_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="choose_hostiko_content">
                <h2>Tại sao chọn chúng tôi?</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_1.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>SSD Chuyên Dụng</p>
                <span>
                  Sử dụng ổ SSD mang lại tốc độ tốt nhất cho khách hàng.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_2.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Bảo mật dữ liệu</p>
                <span>Dữ liệu khách hàng được mã hóa và bảo mật.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_7.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Tiêu Chuẩn Quốc Tế</p>
                <span>
                  Máy chủ vận hành tại các Trung tâm dữ liệu Tier 3 chuẩn quốc
                  tế.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_3.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Dễ Dàng Cài Đặt</p>
                <span>
                  Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_4.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Chính Sách Hoàn Trả</p>
                <span>
                  Hoàn tiền không cần lý do nếu khách hàng không hài lòng.
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="choose_hostiko_box_content hover_effect">
                <figure>
                  <img
                    src="./assets/images/choose_hostiko_5.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <p>Hỗ Trợ 24/7</p>
                <span>
                  Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng mọi
                  lúc.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="data_center_section"
        style={{ padding: "100px 0 100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="data_center_content">
                <h2 className="mb-4">Nơi đặt máy chủ của bạn</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <MapLocation />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
