/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";

export default function Header() {
  const domain = window.location.hostname;
  const logo_w = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-w.png`;

  return (
    <>
      <div className="top_portion">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-md-block d-none">
              <ul className="second_list" style={{ textAlign: "left", paddingBottom: '8px' }}>
                <li>
                  <i className="fa-solid fa-phone-volume" />
                  <a style={{fontSize: '16px', fontWeight: 600}} href="tel:+(84)345993883">+(84)345993883</a>
                </li>
                <li>
                  <i className="fa-solid fa-envelope-open" />
                  <a style={{fontSize: '16px', fontWeight: 600}} href="mailto:admin@vpschinhhang247.com">
                    admin@vpschinhhang247.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <ul className="second_list" style={{ paddingTop: '10px' }}>
                <li>
                  <a style={{fontSize: '16px', fontWeight: 600}} href={URL.Register}>Đăng ký</a>
                </li>
                <li>
                  <a style={{fontSize: '16px', fontWeight: 600}} href={URL.ProductManagement}>Đăng nhập</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header style={{background: 'white'}}>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="/">
              <figure className="mb-0">
                <img src={logo_w} alt="" className="img-fluid" />
              </figure>
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Trang chủ
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    VPS
                  </a>
                  <ServerCategory />
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dedicated Server
                  </a>
                  <DedicatedServerCategory />
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Proxy
                  </a>
                  <ProxyCategory />
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    S5 Proxy
                  </a>
                  <S5ProxyCategory />
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={URL.Guide}>
                    Hướng dẫn
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/agreement">
                    Điều khoản
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/sla">
                    SLA
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Liên hệ
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
