import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

/* eslint-disable no-undef */
export default function MapLocation() {
  const id = "chartdiv";
  useLayoutEffect(() => {
    // Create root
    const root = am5.Root.new(id);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "none",
        projection: am5map.geoNaturalEarth1(),
      })
    );

    // Create polygon series
    chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
      })
    );

    // Create point series
    const pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        latitudeField: "lat",
        longitudeField: "long",
      })
    );

    pointSeries.bullets.push(function () {
      const circle = am5.Circle.new(root, {
        radius: 5,
        fill: am5.color(0xff0000),
        tooltipText: "{name}",
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    pointSeries.data.setAll([
      {
        long: -73.778137,
        lat: 40.641312,
        name: "United States",
      },
      { name: "Canada", lat: 45.4235, long: -75.6979 },
      {
        long: -0.454296,
        lat: 51.47002,
        name: "United Kingdom",
      },
      { name: "France", lat: 48.8567, long: 2.351 },
      { name: "Germany", lat: 52.5235, long: 13.4115 },
      { name: "Việt Nam", lat: 21.0341, long: 105.8372 },
      { name: "Singapore", lat: 1.2894, long: 103.85 },
      { name: "Australia", lat: -26.853, long: 133.275 },
    ]);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <>
      <div id={id} style={{ width: "100%", minHeight: "400px" }}></div>
    </>
  );
}
