/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Header from "layouts/Header";

export default function SLA() {
  return (
    <>
      <div className="contact-sub-banner-section">
        <Header />
        <section className="banner-section contact-banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner-section-content" data-aos="fade-up">
                  <h1 data-aos="fade-up">Cam kết chất lượng dịch vụ</h1>
                  <p className="pp" data-aos="fade-right">
                    Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt
                    uptime từ 99,99% trở lên.
                  </p>
                  <div className="btn_wrapper" data-aos="fade-up">
                    <a href="" className="text-decoration-none contact_us_btn">
                      Xem thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{marginTop: '-32px'}}>
                <div className="banner_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images//dedicated_banner_image.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <>
        <div className="guarantee_support_section">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="guarantee_support_box hover_effect">
                  <figure>
                    <img
                      src="./assets/images/uptime_guarantee_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                  <p>100% Host Node Uptime</p>
                  <p className="pp">
                    Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn
                    được thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm
                    kỹ sư của chúng tôi sử dụng giám sát tích cực để chủ động
                    phát hiện các vấn đề và thực hiện các biện pháp phòng ngừa,
                    giảm thiểu bất kỳ tác động nào khi phần cứng máy chủ bị lỗi
                    có thể gây ra đối với môi trường của bạn.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="guarantee_support_box hover_effect">
                  <figure>
                    <img
                      src="./assets/images/uptime_guarantee_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                  <p>100% Network Uptime</p>
                  <p className="pp">
                    Sử dụng kiến trúc dự phòng và định tuyến BGP thông minh,
                    mạng lõi định tuyến một cách thông minh xung quanh hoạt động
                    mạng gián đoạn nhằm nỗ lực cung cấp 100% thời gian hoạt động
                    và kết nối hiệu suất cao trong tất cả các POP trên toàn thế
                    giới của chúng tôi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <section
        className="price_domain_section"
        style={{ marginTop: "32px" }}
        id="price_domain"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="price_domain_content">
                <h2>Cam kết đền bù phí dịch vụ</h2>
                <p className="pp">
                  Trường hợp tỷ lệ hoạt động hàng tháng của máy chủ ảo giảm
                  xuống dưới 99,9%, chúng tôi sẽ khấu trừ số tiền được tính theo
                  tỷ lệ hiển thị bên dưới cho chi phí sử dụng trong tháng xảy ra
                  sự cố.
                </p>
              </div>
            </div>
          </div>
          <table className="stacktable" data-aos="fade-up">
            <tbody>
              <tr className="heading">
                <th>Thời gian uptime hàng tháng</th>
                <th>Thời gian ở trạng thái không sẵn sàng</th>
                <th>Mức phí dịch vụ đền bù</th>
              </tr>
              <tr>
                <td>Từ 99,9% tới 100%</td>
                <td>Tối đa 43 phút</td>
                <td>0%</td>
              </tr>
              <tr>
                <td>Từ 99% tới ít hơn 99,9%</td>
                <td>Tối đa 430 phút</td>
                <td>30%</td>
              </tr>
              <tr>
                <td>Từ 90% tới ít hơn 99%</td>
                <td>Tối đa 4300 phút</td>
                <td>50%</td>
              </tr>
              <tr>
                <td>Ít hơn 90%</td>
                <td>Hơn 4300 phút</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
