import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function BannerBuyServer() {
  return (
    <>
      {/* BUILD WEBSITE SECTION */}
      <section className="build_website_section">
        <div className="container">
          <div className="build_website_background_image">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="build_website_content">
                  <h2>
                    Dịch vụ chất lượng <span>VPSCHINHHANG247</span>
                  </h2>
                  <p>
                    Chúng tôi tự tin với kinh nghiệm 10 năm kinh doanh, sẵn sàng
                    đem đến cho khách hàng những dịch vụ chất lượng nhất.
                  </p>
                  <figure className="build_website_blue_line mb-0">
                    <img
                      src="./assets/images/build_website_blue_line.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12 d-lg-block d-none" />
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div className="build_website_btn_wrapper">
                  <a href={URL.BuyProduct} className="text-decoration-none get_started_btn">
                    Mua ngay
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
