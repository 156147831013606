/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function S5ProxyCategory() {
  const navigate = useNavigate();
  return (
    <div className="dropdown-menu drop-down-content">
      <ul
        className="list-unstyled drop-down-pages"
        style={{ width: "200px", padding: "8px" }}
      >
        <div className="row">
          <li className="nav-item col-md-6">
            <a
              className="dropdown-item nav-link pb-2"
              style={{marginBottom: '8px'}}
              href=""
              onClick={() => {
                navigate(URL.List922S5Proxy);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/partner/922.png"
                alt="server"
                width={65}
                height={20}
              ></img>
              <span>922 Proxy</span>
            </a>
            <a
              className="dropdown-item nav-link pt-0"
              href=""
              onClick={() => {
                navigate(URL.ListPIAS5Proxy);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/partner/pia.png"
                alt="server"
                width={65}
                height={12}
              ></img>
              <span>PIA Proxy</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}
