/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Header from "layouts/Header";

export default function Contact() {
  return (
    <>
      <div className="contact-sub-banner-section">
        <Header />
        <section className="banner-section contact-banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner-section-content" data-aos="fade-up">
                  <h1 data-aos="fade-up">Liên hệ với chúng tôi</h1>
                  <p className="pp" data-aos="fade-right">
                    Thời gian là tiền bạc! Chúng tôi xem trọng khách hàng và
                    thời gian của mọi người, vì vậy, đội ngũ của chúng tôi luôn
                    làm tốt nhất có thể để giúp bạn giải quyết vấn đề nhanh
                    chóng!
                  </p>
                  <div className="btn_wrapper" data-aos="fade-up">
                    <a
                      href="tel:+(84)345993883"
                      className="text-decoration-none contact_us_btn"
                    >
                      Liên hệ
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_banner_image.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="guarantee_support_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <section className="">
              <div className="container">
                <div className="contact_details_box">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7450.608032391399!2d105.75465019532571!3d20.980447140195814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313453585629140f%3A0x284e05a1a0c4ef2c!2sANLAND%20LAKEVIEW!5e0!3m2!1sen!2s!4v1698811471829!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0, borderRadius: 10 }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <div className="contact_details_content">
                        <h2>Thông tin liên hệ</h2>
                        <p className="pp">
                          <a href="tel:+(84)345993883">
                            <i className="fa-solid fa-phone" /> +(84)345 993 883
                          </a>
                        </p>
                        <p className="pp">
                          <a href="mailto:admin@vpschinhhang247.com">
                            <i className="fa-solid fa-envelope" />{" "}
                            admin@vpschinhhang247.com
                          </a>
                        </p>
                        <p className="pp">
                          <a href="https://t.me/vpschinhhang247">
                            <i className="fa-brands fa-telegram" />{" "}
                            @vpschinhhang247
                          </a>
                        </p>
                        <p className="pp">
                          <a href="https://www.facebook.com/VPSChinhHang247">
                            <i className="fa-brands fa-facebook" />{" "}
                            VPSChinhHang247
                          </a>
                        </p>
                        <p className="pp">
                          <a href="https://t.me/Sp_Vps247">
                            Supporter: <i className="fa-brands fa-telegram" />{" "}
                            @Sp_Vps247
                          </a>
                        </p>
                        <p className="pp">
                          <a href="https://zalo.me/0345993883">
                            Zalo: <i className="fa-brands fa-telegram" />{" "}
                            +(84)345 993 883
                          </a>
                        </p>
                        <p className="pp location pr-0">
                          <i className="fa-solid fa-location-dot" /> SHA5 Toà
                          nhà Anland Lakeview, KĐT Dương Nội, Phường Dương Nội,
                          Quận Hà Đông, Thành phố Hà Nội
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
