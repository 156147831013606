import URL from "constant/url";
import { useNavigate } from "react-router-dom";
import GuideRightContent from "./GuideRightContent";
import Header from "layouts/Header";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide2() {
  const navigate = useNavigate();
  return (
    <>
      <div className="about-sub-banner-section">
        <Header />
        {/* BANNER SECTION */}
        <section
          className="banner-section about-banner-section"
          style={{ padding: 0 }}
        >
          <div className="container">
            <div className="row">
              <div className="banner-section-content">
                <h1 style={{ fontSize: "32px" }}>
                  Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                </h1>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        className="breadcrumb-area bg-primary-gradien"
        style={{ marginTop: "32px" }}
      >
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-8">
              <div className="hm-blog-grid-left deep-shadow rounded-2">
                <div className="hm2-blog-card">
                  <div className="feature-img rounded-top overflow-hidden"></div>
                  <div className="bd-content-wrapper mt-0">
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <span style={{ color: "#e74c3c" }}>
                            <strong>
                              Cách 1: Mở CMD hoặc Powershell và chạy dòng lệnh
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <strong>
                          <em>Net user administrator Mậtkhẩubạnmuốnđổi</em>
                        </strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <strong>
                          <em>
                            ví dụ: Net user administrator vpschinhhang247@12345
                          </em>
                        </strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <em>
                          Lưu ý: Mật khẩu phải bao gồm chữ IN HOA và chữ thường
                          (nếu thêm ký tự đặc biệt càng tốt)
                        </em>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <span style={{ color: "#e74c3c" }}>
                          <span style={{ fontSize: 20 }}>
                            <strong>
                              Cách 2: Thay đổi mật khẩu theo cách truyền thống
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Đầu tiên bạn vào&nbsp;<strong>Start</strong>
                        &nbsp;chọn&nbsp;
                        <strong>Control Panel</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/qWi"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="1.png"
                            src="https://image.maxserver.com/images/2016/04/11/1.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Tiếp theo bạn chọn&nbsp;
                        <strong>Change account type</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/4Bc"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="2.png"
                            src="https://image.maxserver.com/images/2016/04/11/2.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Click vào tài khoản cần đổi mật khẩu, ở đây là tài
                        khoản&nbsp;
                        <strong>Administrator</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/GJD"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="3.png"
                            src="https://image.maxserver.com/images/2016/04/11/3.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Click vào dòng&nbsp;
                        <strong>Change the password</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/TTr"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="4.png"
                            src="https://image.maxserver.com/images/2016/04/11/4.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        <span
                          style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                        >
                          Bạn nhập mật khẩu như&nbsp;hướng dẫn ở dưới, mật khẩu
                          cần có chữ in hoa, chữ thường, kí tự đặc biệt sẽ giúp
                          bảo mật tốt hơn.
                        </span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/nUM"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="5.png"
                            src="https://image.maxserver.com/images/2016/04/11/5.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Cuối cùng&nbsp;bạn click vào&nbsp;
                        <strong>Change password</strong>
                        &nbsp;là xong.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <GuideRightContent
                children={
                  <>
                    <a
                      href=""
                      onClick={() => {
                        navigate(URL.Guide1);
                      }}
                    >
                      Hướng Dẫn Tăng Cường Bảo Mật VPS
                    </a>
                    <br />
                    <a
                      href=""
                      onClick={() => {
                        navigate(URL.Guide3);
                      }}
                    >
                      Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                    </a>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
